import Svg from '@svg/index';
import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Home from '../../icons/appice/home.svg';
import Audience from '../../icons/appice/Audience.svg';
import Connections from '../../icons/appice/Connections.svg';
import Analytics from '../../icons/appice/Analytics.svg';
import MLHub from '../../icons/appice/MLHub.svg';

import Lab from '../../icons/appice/Lab.svg';
import Operations from '../../icons/appice/Operations.svg';
import Source from '../../icons/appice/Source.svg';
import Destination from '../../icons/appice/Destination.svg';
//import Appice_logo from '../../icons/appice/Appice_Logo_new.png';

import {
  AvatarContainer,
  SidebarAvatar,
  SidebarLink,
  SidebarLinksContainer,
  Sider,
  UserName,
} from './styles';

export interface ISidebarProps extends RouteComponentProps<any> {}

class Sidebar extends React.Component<ISidebarProps> {
  public render() {
    return (
      <Sider trigger={null} collapsible={true} collapsed={false}> 
       <AvatarContainer>
           <img src="https://cdp.appice.io/Appice_Logo_new.png" style={{ marginTop:-45,width:150, marginLeft:-75}} />
        </AvatarContainer>
        <SidebarLinksContainer>
          <SidebarLink to="/" exact>
           <img src={ Home } style={{ width:14,height:14}} />
	    <span> &nbsp;&nbsp; Home</span>
          </SidebarLink>
          <SidebarLink to="/home" exact>
             <img src={ Connections }  style={{ width:14,height:14}}/>
            <span> &nbsp;&nbsp; Connections</span>
          </SidebarLink>
          <SidebarLink to="/sources" style={{ paddingTop: 2,paddingLeft: 60 }}>
            <img src={ Source } style={{ width:14,height:14}} /> &nbsp;&nbsp;&nbsp; <span> Sources</span>
          </SidebarLink>
          <SidebarLink to="/destinations"  style={{ paddingTop: 2,paddingLeft: 60 }}>
            <img src={ Destination } style={{ width:14,height:14}} /> &nbsp;&nbsp;&nbsp;<span>Destinations</span>
          </SidebarLink>
          <SidebarLink  to="/audience/explore">
             <img src={ Audience }style={{ width:14,height:14}} />
            <span> &nbsp;&nbsp;&nbsp; Audience </span>
          </SidebarLink>
           <SidebarLink  to={{ pathname: "https://analytics.appice.io" }} target="_blank">
             <img src={ Analytics } style={{ width:14,height:14}} />
            <span> &nbsp;&nbsp;&nbsp; Analytics </span>
          </SidebarLink>
          <SidebarLink to="/">
            <img src={ MLHub } style={{ width:14,height:14}} />
            <span> &nbsp;&nbsp; MLHub </span>
          </SidebarLink>
          <SidebarLink to={{ pathname: "http://mlhub.appice.io/" }} target="_blank" style={{ paddingTop: 2,paddingLeft: 60 }}>
            <img src={ Lab } style={{ width:14,height:14}} /> &nbsp;&nbsp;&nbsp; <span> Lab </span>
          </SidebarLink>
          <SidebarLink to={{ pathname: "http://mlops.appice.io/" }} target="_blank"  style={{ paddingTop: 2,paddingLeft: 60 }}>
            <img src={ Operations } style={{ width:14,height:14}} /> &nbsp;&nbsp;&nbsp;<span>Operations</span>
          </SidebarLink>
        </SidebarLinksContainer>
      </Sider>
    );
  }
}

export default withRouter(Sidebar);
