import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import React from "react";
import "./CircularProgress.css";
import { type } from "os";
type propTypes={
    image:string;
    name:string;
    persentage:number;
}

function CircularProgress({image,name,persentage}:propTypes) {
  return (
    <div className="progress-inner-container p-1  d-flex flex-column align-items-center justify-content-around">
      <p>{name}</p>
      <div className="position-relative">
        <div className="progress-image-div">
          <img src={image} />
        </div>
        <CircularProgressbar value={persentage} maxValue={100} />
       
        
      </div>
      <p>{`${persentage}%`}</p>
    </div>
  );
}

export default CircularProgress;
