
import { type } from "os";
import React, { useEffect, useRef, useState } from "react";
import ReactApexChart from "react-apexcharts";
type customTypes = {
  series: any;
  seriesIndex: any;
  dataPointIndex: any;
  w: any;
};

function HeatMap() {

  const [state, setState] = useState<any>({
    series: [
      {
        name: "Night",
        data: generateData(7, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Evening",
        data: generateData(7, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Afternoon",
        data: generateData(7, {
          min: 0,
          max: 90,
        }),
      },
      {
        name: "Morning",
        data: generateData(7, {
          min: 0,
          max: 90,
        }),
      },
     
    ],
    options: {
      chart: {
        height: 300,
        type: "heatmap",
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#008FFB"],
      title: {
        text: "",
      },
    },
  });

  useEffect(() => {}, []);
  function generateData(count: any, yrange: any) {
    var i = 0;
    var dayNames=["Mon","Tue","Wed","Thur","Fri","Sat","Sun"]
    var series = [];
    while (i < count) {
      var x = dayNames[i]
      var y =
        Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

      series.push({
        x: x,
        y: y,
      });
      i++;
    }
    return series;
  }
  return (
    
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="heatmap"
        height={250}
      />
  
  );
}

export default HeatMap;
