import { Table, Button, Select } from 'antd';
import { ChangeEvent, useEffect, useState } from 'react';
import React from 'react';
import 'antd/dist/antd.css';
import { DatePicker } from 'antd';
import './AudienceExplore.css';
import { Menu, Dropdown, Icon } from 'antd';
import Search from 'antd/lib/input/Search';
import { useHistory } from 'react-router';
// import { data } from './data';
let data = [
  {
    key: 7035648,

    first_name: 'vikash',
    last_name: 'Roy',
    email: 'vikash.Roy@yahoo.com',
    phone: 9823097593,
    fActivity: `1 year ago`,
    lActivity: `3 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7020976,

    first_name: 'rajesh',
    last_name: 'Uddin',
    email: 'rajesh.Uddin@icloud.com',
    phone: 9828557435,
    fActivity: `10 days ago`,
    lActivity: `1 day ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7103119,

    first_name: 'rohit',
    last_name: 'Subram',
    email: 'rohit.Subram@outlook.com',
    phone: 9821167132,
    fActivity: `1 year ago`,
    lActivity: `7 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7074003,

    first_name: 'tilakraj',
    last_name: 'Subramanian',
    email: 'tilakraj.Subramanian@zohomail.com',
    phone: 9824920405,
    fActivity: `2 year ago`,
    lActivity: `3 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7059942,

    first_name: 'aasim',
    last_name: 'Sudha',
    email: 'aasim.Sudha@icloud.com',
    phone: 9823692015,
    fActivity: `1 year ago`,
    lActivity: `4 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7113562,

    first_name: 'rakesh',
    last_name: 'Rajagopal',
    email: 'rakesh.Rajagopal@yahoo.com',
    phone: 9829743967,
    fActivity: `1 year ago`,
    lActivity: `12 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7054299,

    first_name: 'aftab',
    last_name: 'Neelam',
    email: 'aftab.Neelam@icloud.com',
    phone: 9827402738,
    fActivity: `1 year ago`,
    lActivity: `3 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7122038,

    first_name: 'aman',
    last_name: 'Dhawan',
    email: 'aman.Dhawan@gmail.com',
    phone: 9822562588,
    fActivity: `1 year ago`,
    lActivity: `7 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7039699,

    first_name: 'sanjay',
    last_name: 'Chander',
    email: 'sanjay.Chander@icloud.com',
    phone: 9825525307,
    fActivity: `1 year ago`,
    lActivity: `3 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7019205,

    first_name: 'pintu',
    last_name: 'Rana',
    email: 'pintu.Rana@icloud.com',
    phone: 9823766367,
    fActivity: `1 year ago`,
    lActivity: `1 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 5029233,

    first_name: 'somnath',
    last_name: 'Narang',
    email: 'somnath.Narang@zohomail.com',
    phone: 9824821152,
    fActivity: `1 year ago`,
    lActivity: `1 year ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
  {
    key: 7113715,

    first_name: 'devender',
    last_name: 'Kapoor',
    email: 'devender.Kapoor@yahoo.com',
    phone: 9828183382,
    fActivity: `1 year ago`,
    lActivity: `3 days ago`,
    action: <Icon type="more" />,
    edit: <Icon type="edit" />,
  },
];

const columns = [
  {
    title: 'Name',
    dataIndex: 'first_name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
  {
    title: 'First Activity',
    dataIndex: 'fActivity',
  },
  {
    title: 'Last Activity',
    dataIndex: 'lActivity',
  },
  {
    title: '',
    dataIndex: 'action',
  },
  {
    title: '',
    dataIndex: 'edit',
  },
];

function AudienceExplore() {
  const history = useHistory();
  const [selectedRowKeys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [info,setInfo]=useState<any>([])
 
  useEffect(()=>{
setInfo(data)
  },[])
  const handleSearch=(e:any)=>{
setInfo(data.filter((user:any) => {
  return user.first_name ? user.first_name.includes(e.target.value) : false;
}))
  }
  const handleRowClick=(e:any)=>{
history.push({
  pathname: '/audience',
  search: `?name=${e.first_name}`,
  state:e.email
})
  }

  function start() {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setKeys([]);
    }, 1000);
  }

  const onSelectChange = (selectedRowKeys: any) => {
    console.log('selectedRowKeys changed: ', selectedRowKeys);
    setKeys(selectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;
  const menu = (
    <Menu>
      <Menu.Item key="0">
        <a href="http://www.alipay.com/">1st menu item</a>
      </Menu.Item>
      <Menu.Item key="1">
        <a href="http://www.taobao.com/">2nd menu item</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="3">3rd menu item</Menu.Item>
    </Menu>
  );
  return (
    <div className="container-fluid   audience-explore">
      <div className="audience-explore-inner py-3  w-75 align-self-start d-flex justify-content-between  ">
        <strong>Audience / Explore</strong>
        <Dropdown overlay={menu} trigger={['click']}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            Month <Icon type="down" />
          </a>
        </Dropdown>
      </div>
      <div className="w-100 py-3 ">
        <h5>Explore</h5>
      </div>
      <div className="table-container w-100 p-md-3 overflow-hidden">
        <div className="py-2">
          <Search
            placeholder="search "
            onSearch={value => console.log(value)}
            style={{ width: 300 ,margin:"10px 10px 0px 0px"}}
            onChange={handleSearch}
          />
          <Select
            defaultValue="filter"
            style={{ width: 100, marginTop:"10px" }}
            onChange={() => {}}
          ></Select>
        </div>

        <Table
          rowSelection={rowSelection}
          rowClassName={() => 'rowClassName1'}
          columns={columns}
          dataSource={info}
          size="middle"
          pagination={{ pageSize: 6, simple: true }}
          onRowClick={handleRowClick}
          
        />
      </div>
    </div>
  );
}
export default AudienceExplore;
