import React from 'react'
import {ResponsiveContainer,Line,XAxis,YAxis,LineChart}  from 'recharts'
let data=[
    {
        name:"J",
        height:1
    },
    {
        name:"F",
        height:3
    },
    {
        name:"M",
        height:2
    },
    {
        name:"A",
        height:4
    },
    {
        name:"M",
        height:5
    },
    {
        name:"J",
        height:3
    },
    {
        name:"J",
        height:6
    },
    {
        name:"A",
        height:1
    },
    {
        name:"S",
        height:8
    },
    {
        name:"O",
        height:7
    },
    {
        name:"N",
        height:9
    },
    {
        name:"D",
        height:10
    },
]
function LChart() {
  return (
    
    <ResponsiveContainer width="100%"   aspect={2}>
      <LineChart  data={data} margin={{left:-30}} >
        <XAxis dataKey="name" intercept={'preserveStartEnd'}/>
        <YAxis dataKey="height"/>
        <Line type={'monotone'} dataKey="height"/>

       </LineChart>
    </ResponsiveContainer>
    
  )
}

export default LChart