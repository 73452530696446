import React, { Component } from 'react';
import { inject, observer } from 'mobx-react';

import { ISourcesListStore } from '@stores/sourcesList';
import { IDestinationsListStore } from '@stores/destinationsList';
import ConfiguredDestinations from '@components/configuredDestinations';
import { Container } from './styles';
import DestinationsCatalogue from '@components/destinationsCatalogue';

interface IDestinations_newProps {
  history: any;
  sourcesListStore: ISourcesListStore;
  destinationsListStore: IDestinationsListStore;
}

@inject('sourcesListStore', 'destinationsListStore')
@observer
class Destinations_new extends Component<IDestinations_newProps> {
  public render() {
    return (
     
         <iframe src="https://reports.appice.io/#/explore" style={{ border:0}}  width="100%" height="1000px">
</iframe>
    );
  }
}

export default Destinations_new;
