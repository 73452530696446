import './AppBody.css';
import image from '../../images/shape1.png';
import revnue1 from '../../images/revnue1.png';
import revnue2 from '../../images/Total_Order.svg';
import revnue3 from '../../images/revnue3.png';
import revnue from '../../images/checkout.svg';
import mikeIcon from '../../images/mike_icon.png';
import tickIcon from '../../images/tick_icon.png';
import desktopIcon from '../../images/desktop_icon.png';
import mobileIcon from '../../images/mobile_icon.png';
import messageIcon from '../../images/message_icon.png';
import { useLocation } from 'react-router';
import React, { Component } from 'react';
import LChart from '../lineChart/LChart';
import { Link } from 'react-router-dom';
import CircularProgress from '../circularProgress/CircularProgress';
import HeatMap from '../heatmap/HeatMap';
import { Container } from './styles';
import { Dropdown } from 'antd';

function AppBody() {
  const location = useLocation();
  return (
    // <Container className="Destinations">
    <div className="container-fluid py-4  p-md-4 p-sm-2 p-0  body-container">
      <div className=" py-3 mb-5 w-100 align-self-start d-flex justify-content-between  ">
         <strong>Audience / <Link to={"/audience/explore"}>Explore</Link> / Profile</strong> 
      </div>
      <div className="d-flex w-100 justify-content-between  flex-wrap first-container ">
        <div className="profile-container col-lg-2 col-6    d-flex flex-column align-items-center justify-content-center ">
          <div className="user-image">
            <img src={image} />
          </div>
          <h6>{location.search.split('=')[1]}</h6>
          <button className="btn btn-sm btn-primary">Customer</button>
        </div>
        <div className="contact-container  col-lg-3 col-md-6 col-6 ">
          <p className="p-heading">Contact details</p>
          <p>
            <span>Email</span>{' '}
            <span
              style={{
                width: '100%',
                overflow: 'hidden',
                display: '',
                marginLeft: '6px',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                textAlign: 'end',
              }}
            >
              {location.state}
            </span>
          </p>
          <p>
            <span>Phone</span> 9813836317
          </p>
          <p>
            <span>Address</span> 31 soroj nagar
          </p>
          <p>
            <span>City</span> Delhi
          </p>
          <p>
            <span>Country</span> India
          </p>
        </div>
        <div className="demographic-container  col-lg-3 col-md-6 col-12">
          <p className="p-heading">Demographics</p>
          <p>
            <span>Age</span> 24
          </p>
          <p>
            <span>Birthdate</span>14 july,1997
          </p>
          <p>
            <span>Gender</span> Male
          </p>
          <p>
            <span>Martial Status</span> single
          </p>
          <p>
            <span>Income</span>5-10 lac
          </p>
        </div>
        <div className="records-container  col-lg-3 col-md-6 col-12 ">
          <p className="p-heading">Records & transactions</p>
          <p>
            <span>ID</span> 7140632
          </p>
          <p>
            <span>Member</span> 9813836317
          </p>
          <p>
            <span>First seen</span> 10 Mar, 2021
          </p>
          <p>
            <span>Last seen</span> Today
          </p>
          <p>
            <span>Last order</span> 15 Dec, 2022
          </p>
        </div>
      </div>
      {/* ****************revnue container start************* */}
      <div className="d-flex w-100 mt-md-5 mt-3 flex-wrap p-2 revnue-container">
        <div className="row w-100 pb-3">
          <p className="p-heading">Revenue</p>
        </div>
        <div className=" revnue-inner-div my-2 d-flex flex-column justify-content-center  col-lg-3 col-6 p-1 align-items-center">
          <div>
            <img src={revnue1} />
          </div>
          <p>Total spend</p>
          <strong>24902</strong>
        </div>
        <div className=" revnue-inner-div my-2 d-flex flex-column justify-content-center  col-lg-3 col-6 p-1 align-items-center">
          <div>
            <img src={revnue2} />
          </div>
          <p>Average order</p>
          <strong>1576</strong>
        </div>
        <div className=" revnue-inner-div my-2 d-flex flex-column justify-content-center  col-lg-3 col-6 p-1 align-items-center">
          <div>
            <img src={revnue} />
          </div>
          <p>Total order</p>
          <strong>3</strong>
        </div>
        <div className=" revnue-inner-div my-2 d-flex flex-column justify-content-center  col-lg-3 col-6 p-1 align-items-center">
          <div>
            <img src={revnue3} />
          </div>
          <p>Lifecycle</p>
          <strong>Retain</strong>
        </div>
      </div>
      {/* ****************revnue container end************* */}
      {/* ****************transitions and propensityScores container start************* */}
      <div className="transactions-propensity d-flex flex-wrap   mt-md-5 mt-3">
        <div className="transaction-container div-border col-xl-6  col-12  px-sm-5 p-3">
          <div className="d-flex justify-content-between">
            <p className="p-heading">Transactions</p>
            <p className="p-heading">Jan-Dec</p>
          </div>
          <div className="d-flex justify-content-between">
            <div className="d-flex flex-column">
              <strong>Last Purchase: 45 days ago</strong>
              <p>Travel insurence</p>
            </div>
            <div className="d-flex flex-column">
              <strong>Total Purchase: 2</strong>
              <Link className="link" to={'#'}>
                see details
              </Link>
            </div>
          </div>
          <div className="pt-3">
            <div className="mb-3">
              <p>Visits</p>
              <strong>Average Per month : 8</strong>
            </div>

            <LChart></LChart>
          </div>
        </div>
        <div className="propensity-container div-border d-flex  flex-column justify-content-between col-xl-6  col-12 px-sm-5 p-3">
          <p className="p-heading mt-0">Propensity scores</p>
          <p>
            <span>Credit Card</span>
            <span>0.675</span>
          </p>
          <p>
            <span>Home Loan</span>
            <span>0.843</span>
          </p>
          <p>
            <span>Personal Loan</span>
            <span>0.0434</span>
          </p>
          <p>
            <span>Car Loan</span>
            <span>0.9434</span>
          </p>
          <p>
            <span>Insurance Life</span>
            <span>0.3443</span>
          </p>
        </div>
      </div>
      {/* ****************transitions and propensityScores container end************* */}
      {/* ****************Campigns and offer container start************* */}

      <div className="campigns-offer  d-flex  flex-wrap mt-md-5 mt-3">
        <div className="campigns-container div-border  d-flex flex-column  col-xl-6 col-12  px-lg-5 px-md-4 px-3 p-2">
          <div className="d-flex justify-content-between">
            <p className="p-heading">Campigns</p>
            <p className="p-heading">Last 3 mths</p>
          </div>
          <div
            className="d-flex justify-content-between  w-50 py-3 "
            style={{ borderBottom: '1px solid lightgray' }}
          >
            <strong>Total sent</strong>
            <strong>35</strong>
          </div>
          <div className="d-flex justify-content-between py-3 align-items-center campigns-inner ">
            <div className="d-flex ">
              <div className="mike-icon-div">
                <img src={mikeIcon} alt="" style={{ width: '40px' }} />
              </div>
              <div className="">
                <p className="p-heading mx-0 ">Last Campign</p>
                <strong>Need Loan</strong>
              </div>
            </div>
            <div>
              <p className="p-heading mx-0">Campigns</p>
              <strong>Push</strong>
            </div>
            <div>
              <p className="p-heading mx-0">Campigns</p>
              <strong>Clicked</strong>
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 mt-auto">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-heading">Push</p>
              <strong>18</strong>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-heading">In App</p>
              <strong>3</strong>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-heading">Web</p>
              <strong>0</strong>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-heading">Email</p>
              <strong>12</strong>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              <p className="p-heading">WA</p>
              <strong>2</strong>
            </div>
          </div>
        </div>
        <div className="offer-container d-flex flex-column div-border  col-xl-6 col-12  p-1">
          <div className="d-flex justify-content-between">
            <p className="p-heading">Next best offer & Matched segment</p>
            <p className="p-heading">Top 3</p>
          </div>
          <div className="d-flex h-100 justify-content-around p-4 flex-column">
            <div className="d-flex justify-content-between flex-wrap px-md-3 align-items-center">
              <div className="col-lg-7 col-12 col-md-8">
                <img src={tickIcon} />
                <strong className="mx-2">High value home loan</strong>
              </div>
              <div className="col-lg-5 col-12 col-md-4">
                <progress max={100} value={80}></progress>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap px-md-3 align-items-center">
              <div className="col-lg-7 col-12 col-md-8">
                <img src={tickIcon} />
                <strong className="mx-2">Card limit upgrade</strong>
              </div>
              <div className="col-lg-5 col-12 col-md-4">
                <progress max={100} value={60}></progress>
              </div>
            </div>
            <div className="d-flex justify-content-between flex-wrap px-md-3 align-items-center">
              <div className="col-lg-7 col-12 col-md-8">
                <img src={tickIcon} />
                <strong className="mx-2">Gold loan</strong>
              </div>
              <div className="col-lg-5 col-12 col-md-4">
                <progress max={100} value={50}></progress>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ****************Campigns and offer container end************* */}

      {/* *****************activity heatmap and channel Preference start**************** */}
      <div className="heatmap-preference d-flex  flex-wrap mt-md-5 mt-3">
        <div className="heatmap-container div-border  d-flex flex-column  col-xl-6 col-12  px-lg-5 px-md-4 px-3 p-2">
          <p className="p-heading">Activity heatmap</p>
          <div>
            <HeatMap></HeatMap>
          </div>
        </div>
        <div className=" preference-container div-border  d-flex flex-column  col-xl-6 col-12  px-lg-5 px-md-4 px-3 p-2">
          <p className="p-heading">Channel Preference</p>
          <div className="d-flex justify-content-between h-100  align-items-center flex-wrap">
            <CircularProgress
              image={desktopIcon}
              name={'Web'}
              persentage={80}
            ></CircularProgress>
            <CircularProgress
              image={mobileIcon}
              name={'Mobile'}
              persentage={50}
            ></CircularProgress>
            <CircularProgress
              image={mobileIcon}
              name={'App'}
              persentage={90}
            ></CircularProgress>
            <CircularProgress
              image={messageIcon}
              name={'Message'}
              persentage={100}
            ></CircularProgress>
          </div>
        </div>
      </div>
      {/* *****************activity heatmap and channel Preference end**************** */}
    </div>
    //  {/* </Container> */}
  );
}

export default AppBody;
